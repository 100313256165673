<template>
  <q-form ref="editForm">
    <c-card title="LBLBASEINFO" class="cardClassDetailForm">
      <template slot="card-button">
        <q-btn-group outline >
          <c-btn
            v-if="editable"
            :url="saveUrl"
            :isSubmit="isSave"
            :param="dangerStore"
            :mappingType="saveType"
            label="LBLSAVE"
            icon="save"
            @beforeAction="saveMst"
            @btnCallback="saveCallback" 
          />
        </q-btn-group>
      </template>
      <template slot="card-detail">
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
          <c-text
            required
            :editable="editable"
            label="품명 표기"
            name="chmDangerMstName"
            v-model="dangerStore.chmDangerMstName">
          </c-text>
        </div>
        <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2 col-xl-2">
          <c-select
            required
            :editable="editable"
            type="edit"
            codeGroupCd="CHM_DANGER_TYPE_CD"
            itemText="codeName"
            itemValue="code"
            name="chmDangerTypeCd"
            label="구분"
            v-model="dangerStore.chmDangerTypeCd"
          ></c-select>
        </div>
        <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2 col-xl-2">
          <c-text
            required
            :editable="editable"
            label="설치장소"
            name="chmDangerArea"
            v-model="dangerStore.chmDangerArea">
          </c-text>
        </div>
        <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2 col-xl-2">
          <c-datepicker
            :required="true"
            :editable="editable"
            label="설치허가일"
            name="installPermitDate"
            v-model="dangerStore.installPermitDate"
            />
        </div>
        <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2 col-xl-2">
          <c-text
            :editable="editable"
            label="허가번호"
            name="permitNo"
            v-model="dangerStore.permitNo">
          </c-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
          <c-multi-field
            :editable="editable"
            :data="dangerStore"
            label="안전관리자"
            type="user"
            name="chmDangerManageUserId"
            v-model="dangerStore.chmDangerManageUserId">
          </c-multi-field>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
          <c-multi-field
            :editable="editable"
            :data="dangerStore"
            label="LBLMANAGER"
            type="user"
            name="chmDangerUserId"
            v-model="dangerStore.chmDangerUserId">
          </c-multi-field>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
          <c-multi-field
            :editable="editable"
            :data="dangerStore"
            label="안전대리자"
            type="user"
            name="chmDangerSafetyUserId"
            v-model="dangerStore.chmDangerSafetyUserId">
          </c-multi-field>
        </div>
        <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2 col-xl-2">
          <c-text
            :editable="editable"
            label="지정수량 표기"
            name="chmDangerCount"
            v-model="dangerStore.chmDangerCount">
          </c-text>
        </div>
        <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2 col-xl-2">
          <c-text
            :editable="editable"
            label="주용도"
            name="chmDangerMainUse"
            v-model="dangerStore.chmDangerMainUse">
          </c-text>
        </div>
        <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2 col-xl-2">
          <c-plant 
            required
            :editable="editable" 
            type="edit" 
            name="plantCd" 
            v-model="dangerStore.plantCd" />
        </div>
        <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2 col-xl-2">
          <c-checkbox
            :editable="editable"
            :isFlag="true"
            label="LBLUSEFLAG"
            name="useFlag"
            v-model="dangerStore.useFlag"
          />
        </div>
      </template>
    </c-card>
    <c-table
      ref="tableType"
      title="저장품목"
      :columns="gridType.columns"
      :gridHeight="gridHeight"
      :data="dangerStore.typeList"
      :filtering="false"
      :columnSetting="false"
      :usePaging="false"
      noDataLabel="저장품목을 추가하세요."
      :hideBottom="true"
      :editable="editable"
      rowKey="chmDangerMstTypeId"
      selection="multiple"
    >
      <template slot="table-button">
        <q-btn-group outline>
          <c-btn v-if="editable" :showLoading="false" label="LBLADD" icon="add" @btnClicked="addType" />
          <c-btn v-if="editable && dangerStore.typeList && dangerStore.typeList.length > 0" :showLoading="false" label="LBLEXCEPT" icon="remove" @btnClicked="removeType" />
        </q-btn-group>
      </template>
      <template v-slot:customArea="{ props, col }">
        <template v-if="col.name === 'calcuMethod'">
          {{props.row['calcuOriginal'] * props.row['calcuMultiple'] | toThousandFilter}}
        </template>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </q-form>
</template>

<script>
import { uid } from 'quasar'
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'dangerMasterInfo',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        chmDangerMstId: '',
      }),
    },
    dangerStore: {
      type: Object,
      default: () => ({
        chmDangerMstId: '',
        plantCd: '',
        chmDangerArea: '',
        chmDangerManageUserId: '',
        chmDangerMstName: '',
        chmDangerUserId: '',
        chmDangerSafetyUserId: '',
        chmDangerCount: '',
        chmDangerMainUse: '',
        installPermitDate: '',
        permitNo: '',
        useFlag: 'Y',
        chmDangerTypeCd: null,
        typeList: [],
        deleteTypeList: [],
        itemList: [],
        fireFightings: [],
      }),
    },
    isOld: {
      type: Boolean,
      default: () => false
    },
    contentHeight: null,
  },
  data() {
    return {
      gridType: {
        columns: [
          {
            required: true,
            name: 'dangerItemName',
            field: 'dangerItemName',
            label: '품목',
            style: 'width:300px',
            align: 'left',
            sortable: false,
          },
          {
            required: true,
            name: 'chmDangerTypeName',
            field: 'chmDangerTypeName',
            label: '품목 명',
            style: 'width:200px',
            align: 'left',
            type: 'text',
            sortable: false,
          },
          {
            name: 'casNo',
            field: 'casNo',
            label: 'CAS No.',
            style: 'width:150px',
            align: 'center',
            type: 'text',
            sortable: false,
          },
          {
            required: true,
            name: 'calcuOriginal',
            field: 'calcuOriginal',
            label: '품목별 지정수량',
            style: 'width:150px',
            type: 'number',
            sortable: false,
          },
          {
            required: true,
            name: 'calcuMultiple',
            field: 'calcuMultiple',
            label: '배수(사업장 허가)',
            style: 'width:150px',
            type: 'number',
            sortable: false,
          },
          {
            name: 'calcuMethod',
            field: 'calcuMethod',
            label: '적용 지정수량',
            style: 'width:150px',
            type: 'custom',
            sortable: false,
          },
        ],
        height: '300px'
      },
      useFlagItems: [
        { code: 'Y', codeName: '사용' },
        { code: 'N', codeName: '미사용' },
      ],
      editable: true,
      isSave: false,
      saveType: 'POST',
      saveUrl: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '50%',
        top: '10px',
        param: {},
        closeCallback: null,
      },
    };
  },
  computed: {
    gridHeight() {
      let height = this.contentHeight - 370;
      if (height < 300) {
        height = 300;
      }
      return String(height) + 'px';
    }
  },
  watch: {
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.saveUrl = transactionConfig.chm.danger.master.update.url;
      // list setting
    },
    addType() {
      this.popupOptions.title = '위험물 지정수량';
      this.popupOptions.target = () => import(`${'@/pages/chm/base/dangerItemPop.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopupDangerItem;
    },
    closePopupDangerItem(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      let _chmDangerMstName = '';
      if (data && data.length > 0) {
        this.$_.forEach(data, item => {
          this.dangerStore.typeList.push({
            chmDangerMstId: this.popupParam.chmDangerMstId,
            chmDangerMstTypeId: uid(),
            chmDangerTypeName: '',
            chmDangerItemId: item.chmDangerItemId,
            dangerItemName: item.regulatedItemName + ' - ' + item.dangerItemName,
            casNo: '',
            calcuMethod: item.quantity,
            calcuOriginal: item.quantity,
            calcuMultiple: 1,
            regUserId: this.$store.getters.user.userId,
            editFlag: 'C'
          })
          _chmDangerMstName = _chmDangerMstName + (_chmDangerMstName == '' ? '' : ',') + item.regulatedItemName + item.dangerItemName.split('.')[1];
        })
      }
      if (!this.dangerStore.chmDangerMstName) {
        this.dangerStore.chmDangerMstName = _chmDangerMstName;
      }
    },
    removeType() {
      let selectData = this.$refs['tableType'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSGNOITEMS', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$_.forEach(selectData, item => {
          if (!this.dangerStore.deleteTypeList) {
            this.dangerStore.deleteTypeList = []
          }
          if (this.$_.findIndex(this.dangerStore.deleteTypeList, { chmDangerMstTypeId: item.chmDangerMstTypeId }) === -1
            && item.editFlag !== 'C') {
              this.dangerStore.deleteTypeList.push(item)
          }
          this.dangerStore.typeList = this.$_.reject(this.dangerStore.typeList, item)
        })
        this.$refs['tableType'].$refs['compo-table'].clearSelection();
      }
    },
    saveMst() {
      if (this.isOld) {
        this.saveUrl = transactionConfig.chm.danger.master.update.url;
        this.saveType = 'PUT';
      } else {
        this.saveUrl = transactionConfig.chm.danger.master.insert.url;
        this.saveType = 'POST';
      }
      this.$refs['editForm'].validate().then(_result => {
        if (_result && this.$comm.validTable(this.gridType.columns, this.dangerStore.typeList)) { 
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',
            message: 'MSGSAVE', // 저장하시겠습니까?
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.dangerStore.regUserId = this.$store.getters.user.userId
              this.dangerStore.chgUserId = this.$store.getters.user.userId
              
              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.popupParam.chmDangerMstId = result.data
      this.$emit('getDetail');
    },
  }
};
</script>
